const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  companyLogo: state => state.user.companyLogo,
  isAdmin: state => state.user.isAdmin,
  businessAbility: state => state.user.businessAbility,
  deptId: state => state.user.deptId,
  manageerId: state => state.user.manageerId,
  principalId: state => state.user.principalId,
  isOrderHint: state => state.user.isOrderHint,
  isLookBillAuth: state => state.user.isLookBillAuth,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  curPath: state => state.user.curPath,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  topbarRouters: state => state.permission.topbarRouters,
  defaultRoutes: state => state.permission.defaultRoutes,
  sidebarRouters: state => state.permission.sidebarRouters,
}
export default getters
