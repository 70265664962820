<template>
  <div style="padding: 0 15px;" @click="toggleClick">
    <svg
      :class="{'is-active':isActive}"
      class="hamburger"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
    >
     <path d="M1024 896v128H0v-128h1024zM0 256l320 256-320 256V256z m1024 192v128H448V448h576z m0-448v128H0V0h1024z" p-id="1209" fill="#ffffff"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
