<template>
  <div class="div-scrollbar">
    <!-- <div class="div-menu">
      <span class="span-menu-item" @click="toPath('Index')" :class="curPath.indexOf('index') == 0 ? 'active' : ''">首页</span>
    </div> -->

    <div class="div-menu" v-for="(item, index) in permission_routes" :key="item.path + index">
      <div v-if="!item.hidden && item.meta">
        <span class="span-menu-item" :class=" curPath.indexOf(item.path.replace(/(\/)/, '')) == 0 ? 'active' : '' "
          @click="toPath(item.children[0].name)"> {{ item.meta.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { constantRoutes } from "@/router";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 8,
      // 是否为首次加载
      isFrist: false,
      // 当前激活菜单的 index
      currentIndex: undefined,
    };
  },
  computed: {
    theme() {
      return this.$store.state.settings.theme;
    },
    // 顶部显示菜单
    topMenus() {
      let topMenus = [];
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      console.log(this.routers);
      return topMenus;
    },
    // 所有的路由信息
    routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus() {
      var childrenMenus = [];
      this.routers.map((router) => {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path =
                "/redirect/" + router.children[item].path;
            } else {
              if (!this.ishttp(router.children[item].path)) {
                router.children[item].path =
                  router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu() {
      const path = this.$route.path;
      let activePath = this.routers[0].path;
      if (path.lastIndexOf("/") > 0) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      } else if ("/index" == path || "" == path) {
        if (!this.isFrist) {
          this.isFrist = true;
        } else {
          activePath = "index";
        }
      }
      var routes = this.activeRoutes(activePath);
      if (routes.length === 0) {
        activePath = this.currentIndex || this.routers[0].path;
        this.activeRoutes(activePath);
      }
      return activePath;
    },
    ...mapGetters([
      "avatar",
      "permission_routes",
      "sidebar",
      "curPath",
      "name",
    ]),
  },
  beforeMount() {
    window.addEventListener("resize", this.setVisibleNumber);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setVisibleNumber);
  },
  mounted() {
    this.setVisibleNumber();
  },
  methods: {
    toPath(name) {
      this.$router.push({
        name,
      });
    },
    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3;
      this.visibleNumber = parseInt(width / 85);
    },
    // 菜单选择事件
    handleSelect(key, keyPath) {
      this.currentIndex = key;
      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (key.indexOf("/redirect") !== -1) {
        // /redirect 路径内部打开
        this.$router.push({
          path: key.replace("/redirect", ""),
        });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
    },
    // 当前激活的路由
    activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {
          if (key == item.parentPath || (key == "index" && "" == item.path)) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        this.$store.commit("SET_SIDEBAR_ROUTERS", routes);
      }
      return routes;
    },
    ishttp(url) {
      return url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1;
    },
  },
};
</script>

<style lang="scss">
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 60px;
  line-height: 60px;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  color: #7b8494;
  padding: 0 5px;
  margin-left: 40px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #{"var(--theme)"};
  color: #7b8494;
}

/* submenu item */
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 60px !important;
  line-height: 60px !important;
}

.div-scrollbar {
  display: flex;
  line-height: 60px;

  .span-menu-item {
    display: block;
    margin-left: 40px;
    color: #7b8494;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    font-weight: bold;

    &:hover {
      color: #fff;
    }

    &.active {
      color: #fff;

      &::before {
        position: absolute;
        content: "";
        width: 40px;
        height: 4px;
        left: 50%;
        bottom: 0;
        background: #4880ff;
        transform: translate(-50%);
      }
    }
  }
}
</style>
