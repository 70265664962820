var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" }, on: { click: _vm.toggleClick } },
    [
      _c(
        "svg",
        {
          staticClass: "hamburger",
          class: { "is-active": _vm.isActive },
          attrs: {
            viewBox: "0 0 1024 1024",
            xmlns: "http://www.w3.org/2000/svg",
            width: "64",
            height: "64"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M1024 896v128H0v-128h1024zM0 256l320 256-320 256V256z m1024 192v128H448V448h576z m0-448v128H0V0h1024z",
              "p-id": "1209",
              fill: "#ffffff"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }