<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg"
        :unique-opened="true" :active-text-color="settings.theme" :collapse-transition="false" @close="handleClose"
        ref="menus" mode="vertical">
        <sidebar-item v-for="(route, index) in permission_routes" :key="route.path  + index" :item="route"
          :base-path="route.path" :cur-path="curPath" />
      </el-menu>
      
    </el-scrollbar> -->
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <div
        class="submenu-title-noDropdown"
        v-if="curPath.indexOf('index') == 0"
      >
        首页
      </div>
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        v-else
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        @close="handleClose"
        ref="menus"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
          :cur-path="curPath"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";

export default {
  components: {
    SidebarItem,
    Logo,
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters([
      "permission_routes",
      "sidebar",
      "curPath",
      "sidebarRouters",
    ]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  methods: {
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #666;
  border-bottom: 1px solid #eee !important;

  box-sizing: border-box;
  position: relative;
  z-index: 1005;

  &:hover {
    background: none !important;
  }
}

/deep/.submenu-title-noDropdown {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  z-index: 1005;

  &:hover {
    background: none !important;
  }
}

/deep/.el-submenu .el-menu-item {
  height: 30px;
  line-height: 30px;
  padding: 0 30px;
  font-size: 14px;
  color: #242424;

  &:hover {
    color: #4880ff;
  }
}
</style>
