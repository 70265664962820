var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "div-scrollbar" },
    _vm._l(_vm.permission_routes, function(item, index) {
      return _c("div", { key: item.path + index, staticClass: "div-menu" }, [
        !item.hidden && item.meta
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "span-menu-item",
                  class:
                    _vm.curPath.indexOf(item.path.replace(/(\/)/, "")) == 0
                      ? "active"
                      : "",
                  on: {
                    click: function($event) {
                      return _vm.toPath(item.children[0].name)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.meta.title) + " ")]
              )
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }