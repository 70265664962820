<template>
  <section
    class="app-main"
    :style="
      curPath == 'index' || curPath == 'welcome' || curPath == 'cms/index'
        ? 'left: -180px;width: 100vw;z-index:1001'
        : ''
    "
  >
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view class="keep-alive"></router-view>
        <!-- <router-view :key="key" /> -->
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    curPath() {
      return this.$store.state.user.curPath;
    },
    key() {
      return this.$route.path;
    },
  },
  created() {
    console.log(this.curPath);
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  height: calc(100vh - 100px);
  width: 100%;
  position: absolute;
  overflow-y: auto;
  background: #f1f3f9;
}

.app-container {
  min-height: calc(100vh - 120px);
  margin-top: 10px;
  // overflow-y: auto;
  // padding-bottom: 80px;
}

.app-main::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.app-main::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #b3d8ff;
}

.app-main::-webkit-scrollbar-thumb:hover {
  background: #4880ff;
}
.app-main::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  // background: #fff;
  // border-radius: 10px;
}

.hasTagsView {
  .fixed-header + .app-main {
    top: 100px;
    // border-top: 10px solid #f1f3f9;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    //padding-right: 15px;
  }
}
</style>
