import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './assets/styles/element-variables.scss'
Element.Dialog.props.closeOnClickModal.default = false;
Element.Dialog.props.lockScroll.default = false;
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import echarts from 'echarts'

Vue.use(VXETable)
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
//import VCharts from 'v-charts'

import './assets/icons' // icon
import './permission' // permission control
/*import {
  getDicts
} from "@/api/system/dict/data";
import {
  getConfigKey
} from "@/api/system/config";*/
import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    download,
    handleTree
} from "@/utils/ruoyi";
import Pagination from "@/components/Pagination";
//自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"


import Print from '@/utils/print'
// 全局方法挂载
//Vue.prototype.getDicts = getDicts
//Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.$echarts = echarts

Vue.prototype.msgSuccess = function (msg) {
    this.$message({
        showClose: true,
        message: msg,
        type: "success"
    });
}

Vue.prototype.msgError = function (msg) {
    this.$message({
        showClose: true,
        message: msg,
        type: "error"
    });
}

Vue.prototype.msgInfo = function (msg) {
    this.$message.info(msg);
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.use(Print)
Vue.use(permission)

//Vue.use(VCharts)
// 过滤 特殊字符
Vue.prototype.validForbid = function (value) {
    value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "");
    return value;
}
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

// 单独修改用户协议的title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})