var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "div-scrollbar" },
      [
        _c("top-nav", {
          staticClass: "topmenu-container div-menu",
          attrs: { id: "topmenu-container" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile" ? void 0 : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "click" }
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: require("@/assets/image/user_avatar.png") }
              }),
              _c("i", { staticClass: "el-icon-caret-bottom" })
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "ul-el-dropdown-menu",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c("div", { staticClass: "dropdown-menu-top" }, [
                  _c("div", { staticClass: "div-admin" }, [
                    _vm._v(_vm._s(_vm.name))
                  ]),
                  _vm.isAdmin == 1
                    ? _c("div", { staticClass: "div-manage" }, [
                        _vm._v("超级管理员")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.logout($event)
                      }
                    }
                  },
                  [_c("span", [_vm._v("退出登录")])]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }