import {
    login,
    logout,
    getInfo
} from '@/api/login'
import {
    getToken,
    setToken,
    removeToken
} from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        isOrderHint: '',
        isLookBillAuth: '',
        roles: [],
        permissions: [],
        deptId: '',
        manageerId: '',
        principalId: '',
        companyLogo: '',
        isAdmin: '',
        curPath: '',
        businessAbility: '',
        userMsg: {},
        phone: '',
        isSeeNews: '',

    },

    mutations: {
        set_businessAbility: (state, businessAbility) => {
            state.businessAbility = businessAbility
        },
        set_userMsg: (state, userMsg) => {
            state.userMsg = userMsg
        },
        set_phone: (state, phone) => {
            state.phone = phone
        },
        set_isSeeNews: (state, isSeeNews) => {
            state.isSeeNews = isSeeNews
        },

        set_curPath: (state, curPath) => {
            state.curPath = curPath
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        set_companyLogo: (state, companyLogo) => {
            state.companyLogo = companyLogo
        },
        set_isAdmin: (state, isAdmin) => {
            state.isAdmin = isAdmin
        },
        SET_IsOrderHint: (state, isOrderHint) => {
            state.isOrderHint = isOrderHint
        },
        set_isLookBillAuth: (state, isLookBillAuth) => {
            state.isLookBillAuth = isLookBillAuth
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_DeptId: (state, deptId) => {
            state.deptId = deptId
        },
        SET_ManageerId: (state, manageerId) => {
            state.manageerId = manageerId
        },
        SET_PrincipalId: (state, principalId) => {
            state.principalId = principalId
        }
    },

    actions: {
        // 登录
        Login({
            commit
        }, userInfo) {
            const username = userInfo.username.trim()
            const password = userInfo.password
            return new Promise((resolve, reject) => {
                login(username, password).then(res => {
                    setToken(res.data)
                    commit('SET_TOKEN', res.data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({
            commit,
            state
        }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(res => {
                    let data = res.data
                    if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.data.roles)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    /*  
                        控制按钮权限 格式: ["admin:add", "admin:edit", "admin:freeze", "admin:delete"] 
                        页面需要控制 v-hasPermi="['admin:add']"
                    */
                    commit('SET_PERMISSIONS', res.data.permissions)
                    commit('SET_NAME', data.userName)
                    commit('set_isAdmin', data.isAdmin)
                    commit('set_phone', data.loginAccount)
                    resolve(res)


                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 退出系统
        LogOut({
            commit,
            state
        }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_PERMISSIONS', [])
                    removeToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({
            commit
        }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        }
    }
}

export default user