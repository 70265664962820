var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" }
        },
        [
          _vm.curPath.indexOf("index") == 0
            ? _c("div", { staticClass: "submenu-title-noDropdown" }, [
                _vm._v(" 首页 ")
              ])
            : _c(
                "el-menu",
                {
                  ref: "menus",
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "unique-opened": true,
                    "active-text-color": _vm.settings.theme,
                    "collapse-transition": false,
                    mode: "vertical"
                  },
                  on: { close: _vm.handleClose }
                },
                _vm._l(_vm.sidebarRouters, function(route, index) {
                  return _c("sidebar-item", {
                    key: route.path + index,
                    attrs: {
                      item: route,
                      "base-path": route.path,
                      "cur-path": _vm.curPath
                    }
                  })
                }),
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }