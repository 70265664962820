import request from '@/utils/requestCMS'
import qs from 'qs'
// 登录方法
export function login(username, password) {
    const data = {
        phone: username,
        password,
        projectId: 30
    }
    return request({
        url: '/cms/login',
        method: 'post',
        data
    })
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/cms/admin/queryAdminByToken',
        method: 'get'
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/cms/logOut',
        method: 'get'
    })
}