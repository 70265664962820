<template>
  <div class="navbar">
    <!-- <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" /> -->

    <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" /> -->
    <div class="div-scrollbar">
      <!-- <div class="div-menu">
        <span class="span-menu-item" @click="toPath('Index')" :class="curPath.indexOf('index')==0?'active':''">
          首页
        </span>
      </div> -->

      <!-- <div class="div-menu" v-for="(item, index) in permission_routes" :key="item.path  + index">
        <div v-if="!item.hidden&&item.meta">
          <span class="span-menu-item" :class="curPath.indexOf(item.path.replace(/(\/)/,''))==0?'active':''"
            @click="toPath(item.children[0].name)">
            {{item.meta.title}}
          </span>
        </div>

      </div> -->
      <top-nav id="topmenu-container" class="topmenu-container div-menu" />
    </div>
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <!--<el-tooltip content="源码地址" effect="dark" placement="bottom">
          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />
        </el-tooltip>-->

        <!--<el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip>-->

        <!--<screenfull id="screenfull" class="right-menu-item hover-effect" />-->
        <!--全屏-->

        <!--<el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>-->
      </template>
      <!-- <div class="div-msg" @click="toMsg">
        <img src="@/assets/image/top_msg.png" class="div-icon" />
      </div> -->
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img src="@/assets/image/user_avatar.png" class="user-avatar" />
          <!-- <span class="port">供应端</span> -->
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="ul-el-dropdown-menu">
          <div class="dropdown-menu-top">
            <div class="div-admin">{{ name }}</div>
            <div class="div-manage" v-if="isAdmin == 1">超级管理员</div>
          </div>
          <!-- <router-link
            to="/user/profile?show=0"
            class="link-user"
            v-if="isAdmin == 1"
          >
            <el-dropdown-item>
              <svg-icon icon-class="icon-account" /> 账户中心
            </el-dropdown-item>
          </router-link> -->
          <!-- <router-link
            to="/user/profile?show=1"
            class="link-organize"
            v-if="isAdmin == 1"
          >
            <el-dropdown-item>
              <svg-icon icon-class="icon-organize" /> 组织架构
            </el-dropdown-item>
          </router-link> -->
          <!-- <router-link to="/user/profile">
            <el-dropdown-item>帮助中心</el-dropdown-item>
          </router-link> -->
          <!-- <el-dropdown-item @click.native="setting = true">
            <span>主题设置</span>
          </el-dropdown-item> -->
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isExternal } from "@/utils/validate";
import path from "path";
import Breadcrumb from "@/components/Breadcrumb";
import TopNav from "@/components/TopNav";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
export default {
  components: {
    Breadcrumb,
    Hamburger,
    TopNav,
    Screenfull,
    SizeSelect,
  },
  data() {
    return {
      onlyOneChild: null,
      basePath: "",
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters([
      "sidebar",
      "avatar",
      "device",
      "isAdmin",
      "permission_routes",
      "sidebar",
      "curPath",
      "name",
    ]),

    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
  },
  created() {},
  methods: {
    toMsg() {
      this.$router.push({
        path: "/user/profile?show=4",
      });
    },
    toPath(name) {
      this.$router.push({
        name,
      });
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {
          ...parent,
          path: "",
          noShowingChildren: true,
        };
        return true;
      }

      return false;
    },

    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm(
        `<div class="confirm-notice"><h3>确定注销并退出系统吗？</h3><div>`,
        "提示信息",
        {
          closeOnClickModal: false,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          location.href = "/login";
          //window.location.href = 'http://www.dingzhilian.com/login'
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-popper[x-placement^="bottom"] {
  /deep/.popper__arrow {
    top: -1000px;
  }
}

#topmenu-container {
  background: #20222a;
  border-bottom: none;

  /deep/.el-menu-item:not(.is-disabled):hover,
  /deep/ .el-menu-item:not(.is-disabled):focus {
    background: none;
    color: #fff;
  }
}

.ul-el-dropdown-menu {
  width: 160px;
  border-radius: 0;
  border: none;
  text-align: center;
  margin: 0;
  padding: 0 10px;
  right: 0;
  left: auto !important;

  .link-user {
    border-top: 1px solid #f2f2f2;
    padding-top: 10px;
  }

  .link-organize {
    padding-bottom: 10px;
  }

  .dropdown-menu-top {
    padding: 10px 0;
    line-height: 20px;

    .div-admin {
      color: #242424;
      font-size: 13px;
      font-weight: bold;
    }

    .div-manage {
      font-size: 12px;
      color: #666666;
      margin-top: 4px;
    }
  }

  .el-dropdown-menu__item {
    color: #444444;
    font-size: 13px;
    line-height: 26px;

    &:hover {
      background: none;
      color: #4880ff;

      svg {
        color: #4880ff;
      }
    }
  }

  .el-dropdown-menu__item--divided {
    &::before {
      height: 0;
    }

    border-top-color: #f2f2f2;
    color: #9a9a9a;
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin-top: 0;
    font-size: 12px;
  }
}

.navbar {
  height: 60px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  background: #20222a;
  display: flex;
  justify-content: space-between;

  .div-scrollbar {
    display: flex;

    .span-menu-item {
      height: 60px;
      line-height: 60px;
      display: block;
      margin-left: 40px;
      color: #7b8494;
      cursor: pointer;
      font-size: 16px;
      position: relative;
      font-weight: bold;
      border-bottom: 3px solid transparent;
      padding: 0 5px;

      &:hover {
        color: #fff;
      }

      &.active {
        color: #fff;
        border-bottom: 4px solid #4880ff;
      }
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .div-msg {
      margin: 0 12px 0 20px;
      position: relative;

      .div-icon {
        width: 16px;
        height: 14px;
        cursor: pointer;
      }

      .div-spot {
        position: absolute;
        width: 6px;
        height: 6px;
        background: #e64441;
        right: -3px;
        top: -1px;
        border-radius: 50%;
      }
    }

    #header-search {
      width: 200px;
      height: 30px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 18px;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 24px;

      .avatar-wrapper {
        margin-top: 21px;
        position: relative;

        .port {
          position: absolute;
          width: 50px;
          height: 16px;
          background: #ff7611;
          border-radius: 2px;
          opacity: 0.9;
          color: #20222a;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          left: -5px;
          bottom: 19px;
        }

        .user-avatar {
          cursor: pointer;
          width: 16px;
          height: 16px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -14px;
          top: 3px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
