<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" :style="
        curPath == 'index' || curPath == 'welcome'
          ? 'height: 100px'
          : 'height: 100%;'
      " />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
  import RightPanel from "@/components/RightPanel";
  import {
    AppMain,
    Navbar,
    Settings,
    Sidebar,
    TagsView
  } from "./components";
  import ResizeMixin from "./mixin/ResizeHandler";
  import {
    mapState
  } from "vuex";
  export default {
    name: "Layout",
    components: {
      AppMain,
      Navbar,
      RightPanel,
      Settings,
      Sidebar,
      TagsView,
    },
    mixins: [ResizeMixin],
    computed: {
      ...mapState({
        sidebar: (state) => state.app.sidebar,
        device: (state) => state.app.device,
        showSettings: (state) => state.settings.showSettings,
        needTagsView: (state) => state.settings.tagsView,
        fixedHeader: (state) => state.settings.fixedHeader,
        curPath: (state) => state.user.curPath,
      }),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
          mobile: this.device === "mobile",
        };
      },
    },
    methods: {
      handleClickOutside() {
        this.$store.dispatch("app/closeSideBar", {
          withoutAnimation: false,
        });
      },
    },
    mounted() {
      //监听tab键 事件，取消tab键默认行为
      window.addEventListener("keydown", (event) => {
        if (event.keyCode === 9) {
          event.preventDefault();
        }
      });
    },
  };

</script>
<style lang="scss">
  //比不悬浮按钮
  .page-btm-btns {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 180px;
    right: 0px;
    z-index: 999;
    padding: 20px 0 11px;
    border-top: 1px solid #eee;

    //box-shadow: 0px -8px 15px 0px rgba(0, 0, 0, 0.06);
    .el-button--medium {
      border-radius: 2px;
      padding: 12px 20px;
    }

    .el-row {
      display: flex;
      justify-content: center;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #fff;
  }

  .el-upload--picture-card {
    background: #fff;
  }

  //框架样式修改
  .el-popup-parent--hidden {
    overflow: auto;
    padding: 0 !important;
  }

  .el-loading-mask {
    z-index: 1000;
  }

  .el-cascader-menu {
    max-width: 180px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    border-right: 1px solid #dfe4ed;
  }

  .el-form {
    .el-form-item--medium .el-form-item__label {
      line-height: 30px;
    }

    .el-form-item {
      margin-bottom: 10px;
    }
  }

  .el-input--small .el-input__inner {
    height: 30px;
    line-height: 30px;
  }

  .el-input--medium .el-input__inner,
  .el-input__inner {
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
  }

  .el-textarea__inner {
    border-radius: 2px;
  }

  .el-input__suffix {
    display: flex;
    align-items: center;
  }

  .el-input-group__prepend {
    border-radius: 2px;
  }

  .el-range-editor--small.el-input__inner {
    height: 30px !important;
    padding: 1px 10px 2px;
    border-radius: 2px;
  }

  .vxe-input--inner {
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
  }

  .attributebox-btn {
    margin-top: 50px;

    .el-button {
      height: 30px;

      .el-button--mini {
        font-size: 13px !important;
      }
    }
  }

  .el-button--mini {
    line-height: 14px;
    padding: 7px 15px;
    font-size: 13px;
    border-radius: 3px;
  }

  .el-button.is-plain:active,
  .el-button.is-plain:focus {
    border-color: #dcdfe6;
    color: #606266;
  }

  .el-button.is-plain:hover {
    border-color: #4880ff;
    color: #4880ff;
  }

  .el-button--primary.is-plain:active,
  .el-button--primary.is-plain:focus {
    color: #4880ff;
    background: none;
    border-color: #4880ff;
  }

  .el-button--primary.is-plain:hover {
    color: #fff;
    background: #4880ff;
  }

  .el-submenu .el-submenu__title+.el-menu {
    display: block !important;
  }

  .el-message-box {
    padding-bottom: 20px;
    border-radius: 2px;
    width: 450px;

    .el-message-box__content {
      // height: 147px;
      padding: 10px 20px;
    }

    .el-message-box__header {
      padding: 0;
      height: 36px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;

      .el-message-box__headerbtn {
        width: 50px;
        height: 36px;
        margin-right: 0px;
        top: 0;
        right: 0px;
      }

      .el-message-box__title {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        padding-left: 20px;
      }
    }

    .el-message-box__btns {
      padding: 5px 20px 0;

      .el-button--primary:focus {
        background: #4880ff;
        border-color: #4880ff;
      }

      .el-button:first-child {
        float: right;
        margin-left: 10px;
      }
    }
  }

  .el-message {
    min-width: 220px;
  }

  .el-table .el-loading-spinner {
    top: 60px;
    margin-top: 0;
  }

  .el-button--small {
    padding: 7px 15px 8px;
    border-radius: 2px;
  }

  .el-dialog .el-dialog__body {
    min-height: 144px;
  }

  .el-dialog__header {
    .el-dialog__headerbtn .el-dialog__close {
      color: #9f9f9f;
    }

    .el-dialog__headerbtn .el-dialog__close:hover {
      color: #888;
    }

    .el-icon-close:before {
      font-size: 10px;
      font-family: "iconfont";
      content: "\e624";
    }
  }

  .el-dialog .el-dialog__header:first-child .el-dialog__headerbtn {
    width: 50px;
    height: 36px;
    margin-right: 0px;
  }

  .el-dialog .el-dialog__body .el-form-item__label:before {
    margin-right: 0px !important;
  }

  .el-button--primary.is-plain {
    background: #fff;
    border-color: #4880ff;
  }

  .el-tabs--card>.el-tabs__header {
    border-bottom: none;
    background: #f5f5f5;
    padding-top: 14px;
    margin-bottom: 20px;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-bottom: none;
    border-left: none;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
  }

  .el-tabs__item {
    border-left: none;
    font-size: 15px;
    color: #242424;
    font-weight: bold;

    &:first-child {
      margin-left: 14px;
    }

    &.is-active {
      font-size: 16px;
      color: #4880ff;
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
    }
  }

  .el-dialog {
    margin-top: 0 !important;
  }

  .el-form-item__label {
    font-size: 13px;
    color: #242424;
  }

  .el-range-editor--small.el-input__inner {
    height: 32px;
  }

  .el-form-item--medium .el-form-item__content {
    line-height: 30px;
  }

  .el-form-item--medium .el-form-item__label {
    line-height: 32px;
  }

  .el-dialog__title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }

  .el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-dialog .el-dialog__header:first-child {
    padding: 0;
    height: 36px;
    background-color: rgba(242, 242, 242, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-dialog__title {
      padding-left: 20px;
    }

    .el-dialog__headerbtn {
      position: static;
      // margin-right: 20px;
    }
  }

  .el-button--small {
    font-size: 13px;
  }

  .el-form--inline .el-form-item {
    margin-right: 20px;
  }

  .el-table {
    color: #444;
  }

  .el-table .el-table__header-wrapper th {
    color: #242424;
  }

  .el-button--small {
    font-size: 13px;
  }

  .el-form--inline .el-form-item {
    margin-right: 20px;
  }

  .el-table {
    color: #444;
  }

  .el-table--border th,
  .el-table--border td {
    border-right: 1px solid #ebebeb;
  }

  .el-table th.is-leaf,
  .el-table td {
    border-bottom: 1px solid #ebebeb;
  }

  .el-table--group::after,
  .el-table--border::after,
  .el-table::before {
    background-color: #ebebeb;
  }

  .el-table--group,
  .el-table--border {
    border: 1px solid #ebebeb;
  }

  .el-table--border {
    border-right: none;
    border-bottom: none;
  }

  .el-table .el-table__header-wrapper th {
    color: #242424;
  }

</style>
<style lang="scss">
  //系统公共样式

  @import "../assets/iconfont/iconfont.css";

  @font-face {
    font-family: "iconfont";
    src: url("../assets/iconfont/iconfont.eot");
    src: url("../assets/iconfont/iconfont.eot?#iefix") format("embedded-opentype"),
      url("../assets/iconfont/iconfont.woff2") format("woff2"),
      url("../assets/iconfont/iconfont.woff") format("woff"),
      url("../assets/iconfont/iconfont.ttf") format("truetype"),
      url("../assets/iconfont/iconfont.svg#iconfont") format("svg");
  }

  //滚动条样式
  .el-table__body-wrapper,
  .vxe-table--main-wrapper,
  .body--wrapper,
  .logistics-body,
  .process-div,
  .jdgoodsbox-content-left,
  .courier-box-table,
  .city-box,
  .el-cascader__tags,
  .el-dialog__body,
  .el-table,
  .w-e-text,
  .print-images,
  .footer-process,
  .block {
    &::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      background-color: #efefef;
    }

    &::-webkit-scrollbar-track {
      background-color: #efefef;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px !important;
      background-color: #ddd;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #7d7d7d;
    }
  }

  .div-dialog-sku-table {
    max-height: 400px;
    overflow-y: auto;
  }

  .bulk-body-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .app-container {
    position: relative;
    // padding-bottom: 80px;
    // margin: 20px 20px 0;
    // background: #fff;
    overflow: hidden;
    padding: 20px 25px;
    // padding-bottom: 20px;
    background-color: #fff;
    margin: 0 10px 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: #333;

    .ads-parse {
      font-size: 12px;
      color: #4880ff;
      cursor: pointer;
      width: 60px;
    }

    .div-input-limit {
      position: relative;
    }

    .input-limit {
      position: absolute;
      right: 6px;
      font-size: 12px;
      color: #999;
    }
  }

  .app-container1 {
    position: relative;
    overflow: hidden;
    padding-bottom: 80px;
    background-color: #fff;
    margin: 0 10px 0;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #333;
  }

  .el-message--warning {
    top: 40% !important;
  }

  .app-container {
    .div-region {
      position: relative;
      color: #606266;

      .cascader-region {
        height: 30px;
        width: 100%;
        position: absolute;
        left: 0;
        opacity: 0;
        z-index: 99;
      }
    }

    .el-input.is-disabled .el-input__inner {
      background: none;
      color: #606266;
    }

    .el-tooltip__popper {
      max-width: 80%;
    }

    .input-ffffe6 input {
      background: #ffffe6;
    }
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .el-table {
    font-size: 13px;
  }

  .el-table-4880ff {
    color: #4880ff;
    cursor: pointer;
  }

  .el-table-f71751 {
    color: #f71751;
  }

  //展开table的样式
  .child-el-table {
    font-size: 13px;

    .el-table__header-wrapper th {
      background: none;
      color: #999;
      font-weight: normal;
    }

    &.child-el-table-999 {
      .el-table__body-wrapper tr {
        color: #666;
        font-size: 13px;
      }
    }

    .el-table--enable-row-hover .el-table__body tr:hover>td {
      background: none;
    }

    .el-table {
      font-size: 12px;
    }

    .child-btm {
      display: flex;
      font-weight: bold;
      margin-top: 20px;
      padding-left: 40px;

      .actual-delivery {
        width: 300px;
      }

      .deliver-num {
        display: flex;

        .el-table-4880ff {
          span {
            padding-right: 5px;
          }
        }
      }
    }

    .child-btm-left {
      display: flex;

      .div-left {
        width: 300px;
      }

      .reminder {
        font-weight: bold;
        color: #f58a0a;
      }
    }

    .child-btm-right {
      margin-left: auto;
      display: flex;
      font-weight: bold;

      .child-total {
        margin-left: 12px;
        margin-right: 12px;
      }

      .div-billing {
        font-size: 13px;
        color: #ff4d4f;
      }
    }
  }

  //进程样式
  .el-timeline-item:first-child {
    .el-timeline-item__content {
      color: #f60;
    }

    .el-timeline-item__timestamp {
      color: #f60;
    }

    .el-timeline-item__node {
      background: #f60;
    }
  }

  .el-timeline-item__node--normal {
    left: 1px;
    width: 8px;
    height: 8px;
  }

  .el-timeline-item__wrapper {
    padding-left: 20px;
  }

  //物流进程样式
  .logistics-body {
    max-height: 600px;
    overflow-y: auto;

    .log-name {
      margin-bottom: 10px;

      .copy-no {
        color: #4880ff;
        cursor: pointer;
      }

      b {
        font-weight: bold;
        font-size: 14px;
        color: #333;
        padding-right: 10px;
      }
    }

    .logistic-item {
      border-bottom: 1px dashed #ccc;
      margin-bottom: 10px;

      &:last-child {
        border: none;
      }

      .no-data {
        padding-bottom: 10px;
        font-size: 12px;
        color: #999;
      }
    }

    .el-timeline {
      padding: 0;
    }
  }

  //confirm框样式修改
  .confirm-notice {
    color: #333;
    height: 26px;
    margin-bottom: 30px;

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      margin-top: 10px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }
  }

  //弹框提示样式
  .dialog-notice {
    color: #333;
    padding-left: 36px;

    .el-message-box__status {
      left: -36px;
    }

    h3 {
      font-size: 16px;
      position: relative;
      margin: 10px 0 0;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 14px;
      margin: 10px 0 20px;
      line-height: 22px;
      color: #666;
    }
  }

  .page-btm {
    /deep/.el-button--medium {
      border-radius: 2px;
    }
  }

  //表格条件筛选
  .table-screen-20 {
    margin-top: 20px;
  }

  .table-screen {
    font-size: 13px;
    color: #242424;
    margin-bottom: 10px;

    .el-form-item__label {
      font-weight: bold;
    }

    .el-input-group__prepend {
      background-color: #fff;
    }

    .item {
      margin-right: 20px;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
        font-weight: bold;
        // width: 100%;
      }
    }

    .item-search {
      span {
        width: 31px;
      }
    }

    .el-select {
      width: 180px;
    }

    .select-prepend {
      width: 100px;
    }
  }

  //表格操作按钮
  .table-operation {
    display: flex;
    margin-bottom: 10px;

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;

      .date-pick {
        width: 240px;
        margin-right: 10px;
      }
    }
  }

  //表格展开样式
  .div-el-table {
    position: relative;

    .div-expand {
      position: absolute;
      width: 20px;
      z-index: 9;
      left: 10px;
      top: 11px;
    }
  }

  //比不悬浮按钮
  .page-btm-btns {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 180px;
    right: 0px;
    z-index: 999;
    padding: 20px 0 11px;
    border-top: 1px solid #eee;

    //box-shadow: 0px -8px 15px 0px rgba(0, 0, 0, 0.06);
    .el-button--medium {
      border-radius: 2px;
      padding: 12px 20px;
    }

    .el-row {
      display: flex;
      justify-content: center;
    }
  }

</style>
<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }

  .mobile .fixed-header {
    width: 100%;
  }

</style>
